// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/redux/slice/onboarding-slice.ts"
);
import.meta.hot.lastModified = "1725982852000";
}
// REMIX HMR END

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Device, HvacPreferences, OnboardingDTO } from "~/api/types";
import { PreviewData } from "~/modal/preview.types";
import { UserPreferences } from "~/modal/user-preferences.type";

export interface Onboarding {
  user: string | undefined;
  preview: PreviewData | undefined;
  preferences: UserPreferences | undefined;
  device: Device | undefined;
  onboarding: OnboardingDTO | undefined;
  hvacPreferences: HvacPreferences | undefined;
}

const initialState: Onboarding = {
  user: undefined,
  preview: undefined,
  preferences: undefined,
  device: undefined,
  onboarding: undefined,
  hvacPreferences: undefined,
};

export const OnboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<string>) => {
      state.user = action.payload;
    },
    setPreview: (state, action: PayloadAction<PreviewData>) => {
      state.preview = action.payload;
    },
    setDevice: (state, action: PayloadAction<Device>) => {
      state.device = action.payload;
    },
    setPreferences: (state, action: PayloadAction<UserPreferences>) => {
      state.preferences = action.payload;
    },
    setOnboarding: (state, action: PayloadAction<OnboardingDTO>) => {
      state.onboarding = action.payload;
    },
    setHvacPreferences: (state, action: PayloadAction<HvacPreferences>) => {
      state.hvacPreferences = action.payload;
    },
    resetUsers: () => initialState,
  },
});

export const {
  setUser,
  setPreview,
  setPreferences,
  resetUsers,
  setDevice,
  setOnboarding,
  setHvacPreferences,
} = OnboardingSlice.actions;

export default OnboardingSlice.reducer;
